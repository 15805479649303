<template>
    <div class="w-full md:w-2/4 text-center">
        <h1 class="text-missyPink text-4xl md:text-6xl mb-10 text-bodoni-moda">
            <span v-if="!sending && !feedbackSent && !feedbackError">Schade!</span>
            <span v-else>Danke!</span>
        </h1>

        <p class="text-roboto" v-if="!sending && !feedbackSent && !feedbackError">
            Du hast dich erfolgreich vom ROCKZ Club Letter abgemeldet und wirst ab sofort nicht mehr von uns benachrichtigt, sobald es Neuigkeiten rund um MISSY ROCKZ gibt. Schade, dass du uns verlässt, aber vielleicht sieht man sich ja bald mal wieder.:)
            <br><br>
            Doch angemeldet bleiben?
            Hier erneut Registrieren:
            <br><br>
            <a href="https://www.missy-rockz.com/rockz-club-aktionen/missyrockzclub" class="text-missyPink">Jetzt anmelden</a>
            <br><br>
            Wollen wir uns wirklich trennen?<br>
            Schade, dass du dich vom ROCKZ CLUB abgemeldet hast. Wir würden dich bitten dir kurz zwei Minuten Zeit zu nehmen, um uns ein Feedback zu hinterlassen, warum du den Clubletter deabonnierst.
        </p>

        <form method="post" @submit.prevent="sendFeedback" v-if="!sending && !feedbackSent && !feedbackError">
            <div class="flex justify-center w-full mb-6">
                <div class="flex flex-col">
                    <label class="inline-flex items-center mt-3" v-for="(value, index) in questions" :key="index">
                        <input type="radio"
                               name="question[]"
                               @input="form.answer = value"
                               class="form-radio h-5 w-5 text-gray-600 focus:ring-0"
                        >
                        <span class="ml-2 text-gray-700">{{ value }}</span>
                    </label>
                </div>
            </div>

            <div class="mb-4" v-if="showTextarea">
                <textarea name="feedback"
                          class="w-full md:w-96 h-52 rounded-sm"
                          v-model="form.feedback"
                          maxlength="200"
                          placeholder="Schreibe hier dein Feedback">
                </textarea>
            </div>

            <button type="button"
                    @click="sendFeedback"
                    class="bg-green-500 text-white py-2 px-4 rounded-sm">
                <span>Absenden</span>
            </button>
        </form>

        <div class="sk-cube-grid" v-else-if="sending && !feedbackSent && !feedbackError">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
        </div>

        <p class="text-roboto" v-if="feedbackSent">
            Vielen Dank für dein Feedback.
        </p>

        <p class="text-roboto" v-if="feedbackError">
            Leider ist etwas schiefgelaufen. Du kannst uns aber auch eine E-Mail an
            <a href="mailto:service@missy-rockz.com" class="text-missyPink">service@missy-rockz.com</a>
            schicken.
        </p>
    </div>
</template>

<script>
export default {
    name: "NewsletterUnsubscribe",
    props: {
        newsletterId: Object
    },
    data() {
        return {
            questions: {
                1: 'Habe das Interesse verloren.',
                2: 'Mir gefällt der Inhalt nicht.',
                3: 'Ich möchte weniger Mails erhalten.',
                4: 'Sonstiges',
            },
            form: {
                newsletter_id: this.newsletterId,
                answer: null,
                feedback: null
            },
            showTextarea: false,
            sending: false,
            feedbackSent: false,
            feedbackError: false,
        }
    },
    watch: {
        'form.answer'(value) {
            if (value === 'Sonstiges') {
                this.showTextarea = true
            } else {
                this.form.feedback = null
                this.showTextarea = false
            }
        }
    },
    methods: {
        sendFeedback() {
            this.sending = true

            window.axios.post(`/newsletters/subscribers/feedback`, this.form)
                .then(() => {
                    this.sending = false
                    this.feedbackSent = true
                })
                .catch(() => {
                    this.sending = false
                    this.feedbackError = true
                })
        },
    }
}
</script>

<style scoped>

</style>