<template>
    <div class="w-full md:w-2/4 text-center">
        <p class="text-roboto">
            Leider ist dein Verifizierungslink abgelaufen. Bitte melde dich erneut mit deiner E-Mail-Adresse an, um einen neuen Verifizierungslink zu erhalten.
            <br><br>
            Thank you!
            <br><br>
            Your MISSY & ROCKZ CREW:)
        </p>
    </div>
</template>

<script>
export default {
    name: "NewsletterExpired"
}
</script>

<style scoped>

</style>