<template>
    <div class="w-full md:w-2/4 text-center">
        <h1 class="text-missyPink text-4xl md:text-6xl mb-10 text-bodoni-moda">
            Welcome to the ROCKZ CLUB!
        </h1>

        <p class="text-roboto">
            Congratulations ROCKZ FAN! Danke, dass du deine E-Mail-Adresse bestätigt hast. Ab sofort wirst du benachrichtigt, sobald es Neuigkeiten oder geheime Aktionen by MISSY ROCKZ gibt. Ganz exklusiv und noch vor allen Anderen!
            <br><br>
            Bis dahin: stay happy and EIP!
            <br><br>
            your MISSY & ROCKZ CREW:)
        </p>
    </div>
</template>

<script>
export default {
    name: "NewsletterVerify"
}
</script>

<style scoped>

</style>