<template>
    <div class="flex justify-center items-center flex-col content-center w-screen h-full w-screen p-4">
        <img src="/images/missyrockz_logo.png" alt="Logo" class="w-24 mb-12">

        <img v-if="!unsubscribe && !alreadyUnsubscribe" src="/images/octicons_heart.png" class="w-10 mb-8" alt="Heart">

        <div class="sk-cube-grid" v-if="loading">
            <div class="sk-cube sk-cube1"></div>
            <div class="sk-cube sk-cube2"></div>
            <div class="sk-cube sk-cube3"></div>
            <div class="sk-cube sk-cube4"></div>
            <div class="sk-cube sk-cube5"></div>
            <div class="sk-cube sk-cube6"></div>
            <div class="sk-cube sk-cube7"></div>
            <div class="sk-cube sk-cube8"></div>
            <div class="sk-cube sk-cube9"></div>
        </div>

        <newsletter-verify v-if="verified && !loading" />
        <newsletter-expired v-if="expired && !loading" />
        <newsletter-already-verified v-if="alreadyVerified && !loading" />
        <newsletter-never-subscribed v-if="neverSubscribed && !loading" />
        <newsletter-already-unsubscribe v-if="alreadyUnsubscribe && !loading" />
        <newsletter-unsubscribe v-if="unsubscribe && !loading" :newsletter-id="subscriber.newsletter_id" />
    </div>
</template>

<script>
import NewsletterVerify from "../../components/newsletter/NewsletterVerify"
import NewsletterExpired from "../../components/newsletter/NewsletterExpired"
import NewsletterAlreadyVerified from "../../components/newsletter/NewsletterAlreadyVerified"
import NewsletterNeverSubscribed from "../../components/newsletter/NewsletterNeverSubscribed"
import NewsletterAlreadyUnsubscribe from "../../components/newsletter/NewsletterAlreadyUnsubscribe"
import NewsletterUnsubscribe from "../../components/newsletter/NewsletterUnsubscribe"

export default {
    name: "Newsletter",
    components: {
        NewsletterUnsubscribe,
        NewsletterAlreadyUnsubscribe,
        NewsletterNeverSubscribed, NewsletterAlreadyVerified, NewsletterExpired, NewsletterVerify},
    data() {
        return {
            loading: true,
            subscriber: null,
            verified: false,
            expired: false,
            alreadyVerified: false,
            neverSubscribed: false,
            unsubscribe: false,
            alreadyUnsubscribe: false,
            thankForFeedback: false,
            sendingFeedback: false,
        }
    },
    methods: {
        verifySubscriber() {
            window.axios.post(`/newsletters/subscribers/verify`, {
                verify_hash: this.$route.params.hash
            })
                .then(() => {
                    this.verified = true
                    this.loading = false
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        this.neverSubscribed = true
                        this.loading = false
                    }

                    if (error.response.status === 410) {
                        this.expired = true
                        this.loading = false
                    }

                    if (error.response.status === 409) {
                        this.alreadyVerified = true
                        this.loading = false
                    }
                })
        },

        unsubscribeSubscriber() {
            window.axios.post('/newsletters/subscribers/unsubscribe', {
                unsubscribe_hash: this.$route.params.hash
            })
                .then(response => {
                    this.subscriber = response.data.subscriber
                    this.unsubscribe = true
                    this.loading = false
                })
                .catch(error => {
                    if (error.response.status === 404) {
                        this.alreadyUnsubscribe = true
                        this.loading = false
                    }
                })
        },
    },
    mounted() {
        if (this.$route.params.status === 'verify' && this.$route.params.hash) {
            this.verifySubscriber()
        }

        if (this.$route.params.status === 'unsubscribe' && this.$route.params.hash) {
            this.unsubscribeSubscriber()
        }
    }
}
</script>

<style scoped>

</style>