<template>
    <div class="w-full md:w-2/4 text-center">
        <p class="text-roboto">
            Du hast dich noch nicht für den Newsletter angemeldet.
            Registriere dich doch einfach im <a href="https://www.missy-rockz.com/rockz-club-aktionen/missyrockzclub" class="text-missyPink">MISSY ROCKZ CLUB</a>
        </p>
    </div>
</template>

<script>
export default {
    name: "NewsletterNeverSubscribed"
}
</script>