<template>
    <div class="w-full md:w-2/4 text-center">
        <p class="text-roboto">
            Oh no! :( Wir konnten deine E-Mail-Adresse nicht finden. Bitte registriere dich erneut und verifiziere deine E-Mail-Adresse.
            <br><br>
            Fragen? Sende uns eine Mail an: <a href="mailto:service@missy-rockz.com" class="text-missyPink">service@missy-rockz.com</a>
            <br><br>
            your MISSY & ROCKZ CREW:)
        </p>
    </div>
</template>

<script>
export default {
    name: "NewsletterAlreadyUnsubscribe"
}
</script>

<style scoped>

</style>