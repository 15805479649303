<template>
    <div class="w-full md:w-2/4 text-center">
        <p class="text-roboto">
            Du hast diese E-Mail-Adresse bereits verifiziert.<br>
            Fragen? Sende uns eine Mail an: <a href="mailto:service@missy-rockz.com" class="text-missyPink">service@missy-rockz.com</a><br>
            <br>
            your MISSY & ROCKZ CREW:)
        </p>
    </div>
</template>

<script>
export default {
    name: "NewsletterAlreadyVerified"
}
</script>

<style scoped>

</style>